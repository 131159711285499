.wrapper-user-menu {
  position: fixed;
  top: 0;
  /*left: -100%;*/
  right: -100%;
  height: 100%;
  width: 100%;
  background: #000;
  transition: all 0.6s ease-in-out;
}
#active:checked ~ .wrapper-user-menu {
  /*left: 0;*/
  right: 0;
}
.menu-btn {
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.menu-btn span,
.menu-btn:before,
.menu-btn:after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 30%;
  width: 40%;
  border-bottom: 2px solid #000;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.menu-btn:before {
  transform: translateY(-8px);
}
.menu-btn:after {
  transform: translateY(8px);
}

.close {
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: background 0.6s;
}

/* closing animation */
#active:checked + .menu-btn span {
  transform: scaleX(0);
}
#active:checked + .menu-btn:before {
  transform: rotate(45deg);
  border-color: #fff;
}
#active:checked + .menu-btn:after {
  transform: rotate(-45deg);
  border-color: #fff;
}
.wrapper-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.wrapper-user-menu ul {
  list-style: none;
  text-align: center;
  width: 100%;
  padding: 0;
  /* height: 50%; */
  text-underline-offset: 20%;
  text-decoration: none;
  font-size: 20px;
  text-transform: uppercase;
  font-size: 38px;
  font-weight: 800;
}
.wrapper-user-menu ul li {
  height: 13%;
  margin: 15px 0;
}
.wrapper-user-menu ul li a {
  color: #fff;
}
.wrapper-user-menu ul li a:after {
  position: absolute;
  content: "";
  background: #fff;
  /*background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);*/
  /*background: linear-gradient(375deg, #1cc7d0, #2ede98);*/
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50px;
  transform: scaleY(0);
  z-index: -1;
  transition: transform 0.3s ease;
}

.header-checkbox {
  display: none;
}

#active:checked ~ .wrapper-user-menu ul li a {
  opacity: 1;
}
.wrapper-user-menu ul li a {
  transition: opacity 1.2s, transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateX(100px);
}
#active:checked ~ .wrapper-user-menu ul li a {
  transform: none;
  transition-timing-function: ease, cubic-bezier(0.1, 1.3, 0.3, 1); /* easeOutBackを緩めた感じ */
  transition-delay: 0.6s;
  transform: translateX(-100px);
}
.main-nav-list {
  width: 100%;
  margin: 0 auto;
  list-style: none;
  display: flex;
  padding: 0;
}
.main-nav-list li {
  width: 20%;
  visibility: visible;
  display: inline-block;
  padding: 14px 0;
  position: relative;
  text-underline-offset: 0.1em;
  text-underline-offset: 3px;
}

#id {
  position: relative;
  top: -11px;
}
.main-nav-list li a {
  font-size: 21px;

  text-align: center;
  color: #000;
  text-underline-offset: 8px !important;

  text-transform: uppercase;
}
.incategories-text {
  font-size: 18px !important;
  font-weight: 100 !important;
  text-transform: none !important;
  font-family: "Roboto Mono", monospace;
}
.title-header {
  font-size: 21px;
  display: block;
  text-transform: uppercase;
}
.main-nav-list li a:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}
.on-hover-underline {
  z-index: 100;
}

.on-hover-underline:hover {
  text-decoration: underline;
  cursor: pointer;
  text-decoration-thickness: 1px;
}

.on-hover-underline-increase {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 20%;
}
.on-hover-underline-increase:hover {
  text-decoration: underline;

  cursor: pointer;
  text-decoration-thickness: 2px;
}
.pro-arrow {
  text-align: center;
}
#logo {
}
.header-text {
  font-size: 28px !important;
  text-transform: uppercase;
  font-weight: 600;
}
.ani {
  transition: all 0.3s ease;
}
.footer-content {
  text-transform: none;
}
.site-branding {
  width: 240px;
  z-index: 110;
  position: relative;
  margin: 0 auto;
  padding: 19px 0 0 0;
}
.arabic-title {
  font-weight: 600 !important;
  font-size: 26px !important;
}
.cat-title {
  font-size: 24px !important;
  text-transform: uppercase !important;
}
.categ-title {
  font-size: 15px !important;
}
.dropdown-content {
  max-height: 0;
  transition: all 0.3s;
  background-color: white;
  overflow: hidden;
  text-align: center;
  max-width: 0px;
  font-size: 15px;
  overflow: auto;
  grid-template-columns: 1fr;
  padding: 16px 0px;
  grid-gap: 0px;
  display: grid !important;
  margin-top: 10px;
}

.header-drop-down-elements:hover {
  cursor: pointer;
}
.header-drop-down-elements {
  margin: 9px 8px;
}
.dropdown:hover .dropdown-content {
  max-height: 300px;
  height: auto;
  max-width: 705px;
  background-color: #ebebeb;
  z-index: 2;
  text-align: center;
}
.sub-menu li {
  width: 100% !important;
  padding: 0;
}
.site-nav {
  display: block !important;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.masthead {
  width: 100%;
  top: 0;
  z-index: 100;
  text-align: center;
  height: 55px;
}
