.sign {
  color: #212120;
  text-align: center;
  font-size: 23px;
}

@media only screen and (max-width: 600px) {
  .loginframe {
    width: 100% !important;
    height: 100% !important;
    border-width: 0;
    border-radius: 0px;
  }
}

@media only screen and (min-width: 600px) {
  .loginframe {
    background-color: #f5f5f5 !important;
    width: 464px;
    height: 430px;
    margin: 7em auto;
    padding: 1px;
    border: 1px solid #cbcbcb;
    box-shadow: 22px 20px 11px 0px rgb(0 0 0 / 14%);
    padding: 30px;
  }
}
.loginframe {
  background-color: #f5f5f5 !important;
  width: 464px;
  height: 430px;
  margin: 7em auto;
  padding: 1px;
  border: 1px solid #cbcbcb;
  box-shadow: 22px 20px 11px 0px rgb(0 0 0 / 14%);
  padding: 30px;
}
