.image-card {
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 50vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  margin: 10px;
}
.on-hover-unblack {
  opacity: 0.4;
}
.on-hover-unblack:hover {
  opacity: 0;
}
.on-hover-black {
  opacity: 1;
}
.on-hover-black:hover {
  opacity: 0;
}
.content-text {
  text-align: center;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.black-layer {
  width: 100%;
  height: 100%;

  transition: all 0.3s;
  position: absolute;
  top: 0;
  left: 0;
}
.image_x {
  border: 0;
  -ms-interpolation-mode: bicubic;
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.project-wrap {
  position: absolute;
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  color: white;
}
.thumbnail_column .project-wrap {
  position: absolute;
  top: 0;
  left: 0;
}
.thumbnail_column {
  position: relative;

  height: auto;
  margin: 2px;
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 50vh; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  margin-bottom: 12px;
}

@media screen and (max-width: 1024px) {
  .block_wrap.full,
  .block_wrap.half {
    width: calc(100% - 16px) !important;
    height: calc((147vw - 30px) * 0.667) !important;
    margin: 8px 8px !important;
  }
}

.block_wrap {
  position: relative;
  float: left;
  overflow: hidden;
  padding: 0;
  margin: 10px;
}
.info_cont {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  -webkit-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}
.card-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: rgba(0, 0, 0, 0.3);
}
.block_wrap img,
.block_wrap img {
  height: auto;
  width: 100%;
  object-fit: cover;
  position: absolute;
}
.block_wrap.half {
  width: calc(50% - 20px);
}

.block_wrap.half .block_cont {
  /* padding-top: 66.67%; */
  padding-top: 100%;
}
.block_wrap.full .block_cont {
  /* padding-top: calc(66.67% / 2); */
  /* padding-top: 66.67%; */
  padding-top: 100%;
}
.block_wrap a {
  position: absolute;
  width: 100%;
  height: 100%;
}
.block_wrap.full {
  width: calc(100% - 20px);
}
.block_wrap.full a img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block_wrap.half a img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
