/* 
general 
-------
*/

@import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Alexandria&family=Readex+Pro&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");

body {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
}
.container-fluid {
}

.ant-layout {
  background: #fff !important;
}

.mainLayout .ant-layout-header {
  background: white;
  padding: 0;

  line-height: 1.6;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  height: 10px !important;
}

div:lang(ar) {
  font-family: "arabicfont", sans-serif;
}
@font-face {
  font-family: "monotest";
  src: url(assets/test.ttf) format("truetype");
}
@font-face {
  font-family: "arabicfont";
  src: url(assets/Wahran-Bold.otf) format("truetype");
}
div:lang(en) {
  font-size: 15px;
  font-family: "Roboto Mono", monospace;
}

.bgGray {
  background: #fafafa;
}
.hover-opacity:hover {
  opacity: 0.8;
  transition: all 0.3s;
}
/* 
title holder
------------
*/
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: "";
  background: black;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}
.main-logo-text {
  font-size: 18px;

  /* font-weight: 600; */
  color: #a5a5a5;
}
.navbar-text-nav {
  /* font-weight: 500; */
  color: #a5a5a5;
}
.titleHolder h2 {
  font-size: 28px;
  margin: 0;
}
.ant-anchor-link-title {
  color: #a5a5a5;
}
/* 
header
------ 
*/
.ant-layout-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .logo {
  font-size: 22px;

  /* font-weight: 400; */
}

.header .logo .fas,
.header .logo .fa {
  color: black;
  margin: 0 6px 0 0;
  font-size: 28px;
}

.fa {
  margin-inline-end: 6px;
}

.header .logo .fa-regular,
.footer .logo .fa-regular {
  color: black;
  margin: 0 2px 0 0;
  font-size: 28px;
}

.header .logo a {
  color: #333;
}

.header .ant-anchor-wrapper {
  background: none;
}

.header .ant-anchor {
  display: flex;
}

.header .ant-anchor-ink {
  display: none;
}

.header .ant-anchor-link {
  padding: 0 20px;
  line-height: 1.4;
  position: relative;
}

.header .ant-anchor-link:before,
.header .ant-anchor-link:after {
  content: "";
  background: black;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-anchor-link:after {
  left: auto;
  right: 30%;
}

.header .ant-anchor-link.ant-anchor-link-active:before,
.header .ant-anchor-link:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-anchor-link.ant-anchor-link-active:after,
.header .ant-anchor-link:hover:after {
  right: 5%;
  opacity: 1;
}

/* ant menu */
.header .ant-menu {
  border: 0;
  background: none !important;
}

.header .ant-menu li.ant-menu-item {
  position: relative;
  color: #333;
}

.header .ant-menu li.ant-menu-item:before,
.header .ant-menu li.ant-menu-item:after {
  content: "";
  background: black;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-menu li.ant-menu-item:after {
  left: auto;
  right: 30%;
}

.header .ant-menu li.ant-menu-item,
.header .ant-menu li.ant-menu-item-selected {
  border: 0 !important;
}

.header .ant-menu li.ant-menu-item-selected,
.header .ant-menu li.ant-menu-item:hover {
  color: black;
  background: none;
}

.header .ant-menu li.ant-menu-item-selected:before,
.header .ant-menu li.ant-menu-item:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-menu li.ant-menu-item-selected:after,
.header .ant-menu li.ant-menu-item:hover:after {
  right: 5%;
  opacity: 1;
}

/* 
hero
---- 
*/
.heroBlock {
  background-size: cover;
  height: 100vh;
}
.heroBlockforslide {
  height: 100vh;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
}

.heroBlock .slick-dots li button {
  background: #000 !important;
}

.heroBlock .slick-dots li.slick-active button {
  background: black !important;
}

.heroBlock .content {
  padding: 0 0 70px;
}

.heroBlock h3 {
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 30px;
}

.heroBlock p {
  margin: 0 0 30px;
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .btnHolder button,
.heroBlock .btnHolder .fas {
  margin: 0 10px 0 0;
}

/* 
block
----- 
*/
.block {
  padding: 60px 0;
  border-bottom: 1px solid #d9d9d9;
}

/* 
about
----- 
*/
.aboutBlock .contentHolder {
  text-align: center;
  margin: 0 0 50px;
}

.aboutBlock .icon {
  font-size: 40px;
  color: black;
  margin: 0 0 10px;
}

.aboutBlock .content {
  text-align: center;
}

/* 
how it works
------------
*/
.worksBlock {
  background: url("assets/images/bg-works.jpg") no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}

.worksBlock:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.worksBlock h2 {
  color: #fff;
}

.worksBlock .contentHolder {
  text-align: center;
}

.worksBlock .contentHolder button {
  font-size: 40px;
  line-height: 1;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  padding: 30px;
  border-radius: 50%;
  width: 110px;
  height: 110px;
}

.modal.p-0 .ant-modal-body {
  padding: 0;
}

/* 
faqs
----
*/
.faqBlock .ant-collapse {
  margin: 0 0 40px;
}

.faqBlock .quickSupport {
  text-align: center;
  max-width: 740px;
  margin: auto;
}

.faqBlock .fas {
  margin: 0 10px 0 0;
}

/* 
pricing block
-------------
*/
.pricingBlock .ant-list {
  text-align: center;
}

.pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
  box-shadow: 0 0 12px 2px #f3f3f3;
  transform: scale(1.1);
  position: relative;
  z-index: 1;
}

.pricingBlock .ant-card-head-title {
  font-size: 20px;
  /* font-weight: 400; */
}

.pricingBlock .large {
  font-size: 38px;
  line-height: 1;
  margin: 0 0 20px;
}

.pricingBlock .fab {
  margin: 0 10px 0 0;
}

/* 
contact
-------
*/
.contactBlock .ant-form {
  max-width: 640px;
}

.contactBlock .ant-form textarea {
  min-height: 120px;
  height: 120px;
}

/* 
footer
------
*/
.ant-layout-footer {
  background: #ebebeb !important;

  padding: 0;
}
.colophon-cont {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  overflow: hidden;
  text-align: left !important;
  padding: 0 10px 132px 10px;
}
.footer .logo {
  font-size: 22px;

  margin: 0 0 20px;
}

.footer .fas {
  color: black;
  font-size: 28px;
  margin: 0 5px 0 0;
}

.footer .logo a {
  color: #fff;
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;

  padding: 0;
  font-size: 24px;
}

.footer .socials li {
  margin: 0 10px;
}

.footer .socials a {
  color: black;
}

.footer .socials a:hover {
  text-decoration: underline;
  /*color: black;*/
}

.footer .goTop:hover {
  opacity: 0.7;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
  width: 100%;
  background-color: white;
}

/* 
responsive
----------
*/
@media only screen and (max-width: 991px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1.14);
  }
}

@media only screen and (max-width: 767px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1);
  }

  .mobileVisible {
    display: block;
  }

  .mobileHidden {
    display: none;
  }
  .pro-arrow {
    top: 0px;
  }
  .vertical-line {
    display: none;
  }
}
@media only screen and (max-width: 1000px) {
  .title-header {
    font-size: 17px !important;
  }
}
.vertical-line {
  float: left;
  height: 500px;
  width: 1px;
  border-right: 1px solid #d9d9d9;
}

.vertical-line span {
  display: block;
  background: white;
  padding: 10px;
  margin: 241px -25px;
  color: #d9d9d9;
  z-index: 10;
  font-size: 26px;
}
.clickedJop {
  max-height: 300px !important;
}
.TeamMembers_details {
  font-size: 13px;
  line-height: normal;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
  overflow: hidden;
  max-height: 0;
}
.langue-text {
  cursor: pointer;
  transition: all 0.4s;
}

.hover-text-read-more {
  text-decoration: underline;
  text-underline-position: under;
  /* font-weight: 800; */
}
@media only screen and (max-width: 991px) {
  .main-logo-text {
    display: none;
  }
}
.ant-btn-primary {
  color: #fff;
  border-color: black !important;
  background: black !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.normal_login_remember {
  filter: hue-rotate(120deg);
}
.ant-checkbox-input {
  background-color: red;
  border-color: red;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: black !important;
}

.loginframe {
  background-color: #f5f5f5 !important;
  width: 464px;
  height: 430px;
  margin: 7em auto;
  padding: 1px;
  border: 1px solid #cbcbcb;
  box-shadow: 22px 20px 11px 0px rgb(0 0 0 / 14%);
  padding: 30px;
}

.sign {
  color: #212120;
  text-align: center;
  font-size: 23px;
}

@media only screen and (max-width: 600px) {
  .loginframe {
    width: 100%;
    height: 100%;
    border-width: 0;
    border-radius: 0px;
  }
}
