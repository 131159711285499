.collapsible-checkbox {
  display: none;
}
.wrap-collabsible {
  margin: 1.2rem 0;
}
.lbl-toggle {
  font-size: 1.2rem;
  color: #404040;
  cursor: pointer;
  transition: all 0.25s ease-out;
}
.card label {
  font-size: 15px;
}
.lbl-toggle:hover {
  color: black;
}
.lbl-toggle::before {
  content: " ";
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: 0.7rem;
  transform: translateY(-2px);
  transition: transform 0.2s ease-out;
}
.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}
.collapsible-content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}
.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 350px;
}
.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.collapsible-content .content-inner {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0.5rem 1rem;
}
.collapsible-content p {
  margin-bottom: 0;
}
